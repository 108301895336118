import React, { Fragment } from 'react'
import styled from 'react-emotion'
import { Transition } from 'react-transition-group'

const duration = 200

const transitionStyles = {
  entering: { height: 300, overflow: 'hidden' },
  entered: { height: 300, overflow: 'visible' },
  exiting: { height: 0, overflow: 'hidden' },
  exited: { height: 0, overflow: 'hidden' },
}

const MenuBody = styled.div`
  background: ${props => props.theme.purple};
  color: ${props => props.theme.lightPurple};
  position: absolute;
  top: 75px;
  left: 0;
  width: 100vw;
  max-width: 100%;
  z-index: 1002;
  transition: height ${duration}ms ease-in-out;
  height: 0;
  overflow: hidden;

  text-align: center;

  & p {
      margin-bottom: 10px;
      font-size: 14px;
      text-transform: uppercase;
      line-height: 1.25;
  }
  
  & ul {
      list-style-type: none;
      list-style-image: none;
      margin-bottom: 2.5em;
      margin-left: 0;

      &:last-child {
          margin-bottom: 5em;
      }

      & li {
          margin-bottom: 1.25rem;
      }
  }

  & a {
    color: ${props => props.theme.yellow};
    /*text-shadow: -2px 0 ${props => props.theme.red},0 2px ${props =>
  props.theme.red},2px 0 ${props => props.theme.red},0 -2px ${props =>
  props.theme.red};*/
    text-align: center;
    font-size: 30px;
    font-family: ${props => props.theme.headerFont};
    text-decoration: none;
  }

  &::after {
    content: "";
    position: absolute;
    bottom: -30px;
    right: 0;
    left: 0;
    width: 0;
    border-left: 100vw solid ${props => props.theme.purple};
    border-right: 0px solid transparent;
    border-top: 0px solid transparent;
    border-bottom: 30px solid transparent;
}
`

const MenuBackground = styled.div`
  content: '';
  background: ${props => props.theme.darkPurple};
  opacity: 0.9;
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 1000;
`

const Menu = ({ visible, children, onBackgroundClick }) => {
  return (
    <Fragment>
      {!visible ? null : <MenuBackground onClick={onBackgroundClick} />}
      <Transition in={visible} timeout={duration}>
        {state => {
          return (
            <MenuBody style={{ ...transitionStyles[state] }} visible={visible}>
              {children}
            </MenuBody>
          )
        }}
      </Transition>
    </Fragment>
  )
}

export default Menu
