// import React from 'react'
import styled from 'react-emotion'

const PageBody = styled.div`
    background-color: ${props => props.theme.white};
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: ${props => props.theme.paddingSize}px;
    position: relative;
    padding: ${props => props.theme.paddingSize / 2}px;
    font-size: 18px;
    font-family: ${props => props.theme.serifFont};
    line-height: 30px;
    padding-bottom: ${props => props.theme.paddingSize * 1.7}px;

    &::before {
        content: "";
        position: absolute;
        top: -${props => props.theme.paddingSize}px;
        right: 0;
        left: 0;
        width: 0;
        border-right: 100vw solid ${props => props.theme.white};
        border-left: 0px solid transparent;
        border-bottom: 0px solid transparent;
        border-top: ${props => props.theme.paddingSize}px solid transparent;
    }

    & a {
        color => ${props => props.theme.red};
        border-bottom: 2px solid ${props => props.theme.darkerYellow};
    }
`

export default PageBody
