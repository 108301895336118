const theme = {
  white: '#ffffff',
  yellow: '#FFDE6A',
  darkerYellow: '#ffbc37',
  purple: '#36235E',
  mediumPurple: '#271847',
  lightPurple: '#C4B8DE',
  darkPurple: '#0B0517',
  red: '#C94242',
  lightGreen: '#F5FAF7',
  darkGrey: '#19181C',

  bodyFont: 'PT Sans, sans-serif',
  serifFont: 'PT Serif',
  headerFont: 'Sansita One, cursive',

  paddingSize: 42,
}

export default theme
