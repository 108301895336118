import styled from 'react-emotion'

const SiteContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  font-family: ${props => props.theme.bodyFont};
  background-color: ${props => props.theme.purple};
  overflow-x: hidden;
  position: relative;

  &::before {
    content: '';
    background-image: url(${props =>
      props.background ? props.background : null});
    background-size: cover;
    background-blend-mode: soft-light;
    opacity: 0.25;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
  }
`

export default SiteContainer
