import React from 'react'
import styled from 'react-emotion'

import FacebookIcon from '../common/icons/icon-facebook'
import TwitterIcon from '../common/icons/icon-twitter'

const FooterContainer = styled.footer`
  background: ${props =>
    props.transparent ? 'transparent' : props.theme.purple};
  color: ${props => props.theme.white};
  font-family: ${props => props.theme.bodyFont};
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  padding: 15px 30px;
  z-index: 20;

  & > div {
    display: flex;
    align-items: center;

    & a {
      margin-right: 10px;
      line-height: 1;
      & svg path {
        fill: #676C9C;
      }
      &:hover svg path {
        fill: #C4B8DE;
      }
    }
  }
`

const Footer = props => (
  <FooterContainer transparent={props.transparent}>
    <div>
      <a href="#asdf">
        <FacebookIcon height={20} />
      </a>
      <a href="#asdf">
        <TwitterIcon height={35} />
      </a>
    </div>
    <div>
      &copy; {new Date().getFullYear()} Hitchhiker's Guide to Javascript
    </div>
  </FooterContainer>
)

export default Footer
