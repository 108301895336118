import React, { Component } from 'react'
import {Link} from 'gatsby'
// import css from 'emotion'
import styled from 'react-emotion'

import Menu from './menu'

import logo from '../../assets/images/logo.svg'
import menu from '../../assets/images/icon-menu.svg'
import menuClose from '../../assets/images/icon-menu-close.svg'

const HeaderContainer = styled.header`
  color: ${props => props.theme.yellow};
  position: relative;

  & > div:first-child {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    background: ${props => props.theme.purple};
    position: ${props => (props.menuVisible ? 'absolute' : 'relative')};
    ${props =>
      props.menuVisible ? `z-index: 1001; width: 100%; background-color` : ''};
  }

  & > div:nth-child(2) {
    text-align: center;
  }

  & h1 {
    margin: 0;

    & span {
      color: ${props => props.theme.yellow};
    }
  }
`
const Logo = styled.img`
  width: 83px;
  margin-bottom: 0;
`

const MenuButton = styled.button`
  background: transparent;
  border-color: transparent;
  padding: 0;

  &:focus {
    outline: none;
  }

  & img {
    margin: 0;
    margin-right: ${props => (props.visible ? '5px' : '0')};
  }
`

class Header extends Component {
  state = {
    menuVisible: false,
  }

  toggleMenu = () => {
    this.setState(state => {
      return { menuVisible: !state.menuVisible }
    })
  }

  render() {
    return (
      <HeaderContainer {...this.props} menuVisible={this.state.menuVisible}>
        <div>
          <h1>
            <Link
              to="/"
              css={{
                textDecoration: 'none',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Logo src={logo} />
            </Link>
          </h1>

          <MenuButton
            onClick={this.toggleMenu}
            visible={this.state.menuVisible}
          >
            <img src={this.state.menuVisible ? menuClose : menu} alt={this.state.menuVisible ? "Close Menu" : "Open Menu"} />
          </MenuButton>
        </div>
        <Menu
          visible={this.state.menuVisible}
          onBackgroundClick={this.toggleMenu}
        >
          <p>The Book</p>
          <ul>
            <li>
              <Link to="/table-of-contents">Table of Contents</Link>
            </li>
            <li>
              <Link to="/thanks">Thanks & Contributions</Link>
            </li>
          </ul>

          <p>The Human Who Wrote The Book</p>
          <ul>
            <li>
              <Link to="/about">About Me</Link>
            </li>
            <li>
              <Link to="/contact">Contact Me</Link>
            </li>
          </ul>
        </Menu>
      </HeaderContainer>
    )
  }
}

export default Header
